@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_container {
  display: inline-block;
  margin-left: 5px;
}

.loading_spinner {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 5px solid #534c3a;
  border-top: 5px solid var(--gold-col);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loading_container_large {
  composes: loading_container;
  margin-left: 0;
  height: 5em;
  width: 5em;
}

.loading_spinner_large {
  composes: loading_spinner;
  width: 5em;
  height: 5em;
}

.loading_container_floating {
  composes: loading_container;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--workspace-background-col);
  padding: 1em;
  z-index: 10000;
  box-shadow: 1px 0px 15px -1px var(--taskbar-background-col);
  font-size: 1rem;
}

.loading_container_floating_round {
  composes: loading_container_floating;
  border-radius: 50%;
  height: 7em;
  width: 7em;
}

.loading_spinner_floating {
  composes: loading_spinner;
  width: 5em;
  height: 5em;
}

.loading_spinner_floating_small {
  composes: loading_spinner;
  display: inline-block;
  position: absolute;
  left: 10px;
  bottom: 35px;
  transform: translate(-50%, -50%);
  background-color: var(--workspace-background-col);
  padding: 1em;
  z-index: 10000;
  box-shadow: 1px 0px 15px -1px var(--taskbar-background-col);
  font-size: 1rem;
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
