.switch_button {
  font-size: 0.9rem !important;
}

.switch_button_selected {
  composes: switch_button;
}

.switch_button_selected:hover {
}
