/* Hamburger */

.hamburger_nav_bar_permanent {
  display: none !important;
}

.hamburger_mobile_nav_container {
  display: flex !important;
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: var(--navbar-background-color);
  z-index: 1000;
  transition: border-radius 0.1s ease, height 0.1s ease, width 0.1s ease,
    top 0.1s ease, left 0.1s ease;
}

.hamburger_mobile_nav_container_button {
  composes: hamburger_mobile_nav_container;
  border-radius: 1.5em;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
}

.hamburger_mobile_nav_container_menu {
  composes: hamburger_mobile_nav_container;
  width: var(--navbar-width);
  min-width: var(--navbar-width);
  padding-bottom: 1em;
  flex-direction: column;
  z-index: 101;
}

.hamburger_mobile_nav_click_catcher {
  position: fixed;
  display: initial !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

/* Mobile */

@media (max-width: 1030px), (pointer: coarse) {
  .nav_bar_permanent {
    display: none !important;
  }

  .mobile_nav_container {
    display: flex !important;
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: var(--navbar-background-color);
    z-index: 1000;
    transition: border-radius 0.1s ease, height 0.1s ease, width 0.1s ease,
      top 0.1s ease, left 0.1s ease;
  }

  .mobile_nav_container_button {
    composes: mobile_nav_container;
    border-radius: 1.5em;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
  }

  .mobile_nav_container_menu {
    composes: mobile_nav_container;
    width: var(--navbar-width);
    min-width: var(--navbar-width);
    padding-bottom: 1em;
    flex-direction: column;
    z-index: 101;
  }

  .mobile_nav_click_catcher {
    position: fixed;
    display: initial !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }
}

/* Normal */

.nav_bar_permanent {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: var(--navbar-width);
  min-width: var(--navbar-width);
  border: 1px solid black;
  padding: 0;
  background-color: var(--navbar-background-color);
}

.mobile_nav_container {
  display: none;
}

.mobile_nav_click_catcher {
  display: none;
}

/* border: 0,
borderLeft: chosen ? 6 : 0,
borderColor: '#0000ff',
borderRadius: 0, */

.nav_button {
  font-size: 1.2rem;
  /* font-weight: bold; */
  cursor: pointer;
  color: var(--primary-text);
  height: 3.5rem;
  background-color: var(--navbar-background-color);
  border: 0px;
  text-align: left;
  padding-left: 3px;
  font-weight: normal !important;
}

.nav_button_disabled {
  color: var(--navbar-background-color);
}

.nav_button_disabled:hover {
  background: none !important;
}

.nav_button_chosen {
  composes: nav_button;
  padding-left: 0;
  border-left: 4px solid white;
}

.nav_button_chosen > div {
  padding-left: 30px;
  height: 100%;
  background-color: #0000 !important;
  /*#FFF2 !important;*/
}

.nav_button_chosen > div > div > span {
  font-weight: 500 !important;
}

.nav_button_disabled > div {
  color: var(--navbar-background-color);
}

.nav_button_disabled > div:hover {
  background: none !important;
}

.nav_button_chosen:hover,
.nav_button_chosen:focus,
.nav_button:hover,
.nav_button:focus {
  background-color: grey;
}

.list_item_button > div > span {
  font-weight: normal !important;
}

.list_item_button:hover {
  background: none !important;
}

.close_nav_bar_button {
  justify-content: end;
  display: flex;
}
