.platform_switcher_base {
  position: relative;
}

.click_catcher {
  position: fixed;
  display:initial !important;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index:100;
}

.platform_switcher_body {
  max-width: 50vw;
  min-width: 255px;
  min-height: 128px;
  margin: 4px 0 0;
  padding: 16px;
  top:0;
  right: 0;
  position: absolute;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  background-color: var(--header-color);
  z-index: 101;
  text-align: left;

}

.app_collection {
  margin-top: 16px;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
}

.app_button_container {
  width: 100%;
}

a.app_button {
  justify-content: start;
  fill: var(--gold-col);
  color: var(--primary-text);
  background-color: #2b2b2b;
  text-decoration: none;
  padding: 12px 12px 12px 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  height: 56px !important;
  width: 100%;

}

.app_button svg {
  color: var(--gold-col);
  width: 20px;
  height: 20px;
  padding: 6px;
  margin-right: 4px;
  box-sizing: content-box;
}