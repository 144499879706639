:root {
  --chart-header-height: 3rem;
  --chart_height: calc(44vh - var(--app-header-height));
}

.workspace_container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: var(--workspace-background-col);
  overflow: clip;
}

.workspace_content {
  height: 100%;
  width: 100%; /* room for task_bar */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*border: 1px solid black;*/
  background-color: var(--workspace-background-col);
}

.map_container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  flex-shrink: 1;
}

.map_container_min {
  display: none;
  composes: map_container;
}

.map_container_max {
  height: 100%;
  composes: map_container;
}

.chart_container {
  height: var(--chart_height);
  min-height: var(--chart_height);
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  max-width: calc(min(100%, 100vw - var(--taskbar-width)));
  overflow: hidden;
  background-color: var(--chart_background_color);
}

.chart_container_min {
  display: none;
  composes: chart_container;
}

.chart_container_max {
  height: 100%;
  max-width: 100%;
  composes: chart_container;
}

.chart_header_wrapper {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px 10px 0px 30px;
}

.chart_header_min {
  display: none;
  composes: chart_header_wrapper;
}

.chart_header {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: var(--chart-header-height);
}

.chart_header_left {
  text-align: left;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
}

.chart_header_right {
  text-align: right;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink:0;
}

.chart_container_inner {
  height: 100%;
  max-height: calc(100% - var(--chart-header-height) - 1px);
}

.no_segment_message {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.noprojects, .nodata {
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
