.dayChangeButton {
  border-radius: 0;
}

.playButtonPlaying {
  composes: dayChangeButton;
  color: #00a000;
}

@keyframes realtime_thumb_pulse {
  0% {
    box-shadow: 0 0 0 0px color-mix(in srgb, var(--realtime-col), #fff 15%);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
