.table {
  /*width: 100%;*/
  height: 100%;
  max-height: 100%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid orange; */
  overflow-y: auto;
}
