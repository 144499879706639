.header_padded {
  text-align: left;
  padding: 10px 10px 6px 0;
  margin: 0;
  font-weight: normal;
  font-size: 1.2em;
  padding-left: var(--taskbar-margin-left);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bookmark_toolbar_list {
  margin-left: 0;
  overflow-y: scroll;
  /*max-height:20vh;*/
  flex-grow: 0;
  width: var(--taskbar-width);
  box-sizing: border-box;
  padding-top: 0;
  scrollbar-width: thin;
  scrollbar-color: var(--workspace-background-col) var(--background-col);
}

.no_bookmarks_message {
  padding-left: var(--taskbar-margin-left) !important;
  text-align: left;
  font-style: italic;
}

.bookmark_toolbar_list::-webkit-scrollbar {
  background-color: var(--background-col);
  width: 10px;
}

.bookmark_toolbar_list::-webkit-scrollbar-thumb {
  background-color: var(--workspace-background-col);
  border-radius: 10000px;
}

.bookmark_item_button {
  padding-left: var(--taskbar-margin-left) !important;
}

.bookmark_edit_button {
  opacity: 0;
}

.bookmark_edit_button:hover {
  opacity: 1;
}

.bookmark_delete_button {
  width: 0.5em;
  opacity: 0.1;
  margin-left: -16px;
  transform: translateX(50%);
  transition:
    width 0.5s,
    opacity 0.5s,
    margin-left 0.1s,
    transform 0.1s;
}

.bookmark_delete_button:hover {
  width: auto;
  padding-right: 16px;
  margin-left: -16px;
  opacity: 1;
  transform: translateX(16px);
}

.edit_name_field {
  margin-left: var(--taskbar-margin-left) !important;
}

.confirm_delete_field {
  composes: edit_name_field;
  color: lightpink;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.search_text_field {
  border: none !important;
  width: 100%;
}