.tooltip,
button.info_hover {
  background-color: color-mix(in srgb, var(--gold-col) 75%, transparent);
  padding: 0.1em;
  margin-left: 0.5em;
}

.tooltip:hover,
button.info_hover:hover {
  background-color: color-mix(in srgb, var(--gold-col) 100%, transparent);
}

.tooltip svg,
button.info_hover svg {
  height: 0.5em;
  width: 0.5em;
  fill: black;
}

.info_walkme_crumb {
  display:inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
  padding-left: 0.3em;

}

.info_walkme_crumb svg {
  fill: color-mix(in srgb, var(--gold-col) 75%, transparent);
  height: 0.7em;
  width: 0.7em;
}

.info_walkme_crumb:hover svg {
  fill: color-mix(in srgb, var(--gold-col) 100%, transparent);
  height: 0.7em;
  width: 0.7em;
}