.highcharts-tooltip > span {
  color: var(--primary-text);
  background-color: var(--workspace-background-col);
  text-align: left;
  padding: 2em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px #0008;
}

.chart-legend h1 {
  color: var(--primary-text);
  font-size: 1rem;
  font-weight: bold;
}

.chart-legend table {
  width: 100%;
  border-spacing: 0;
}

.chart-legend td {
  color: var(--primary-text);
  padding-bottom: 0.5em;
}

.chart-legend tr:last-child td {
  padding-bottom: 0;
}

.chart-legend td span {
  margin-right: 0.4em;
  margin-left: -0.2em;
}

.chart-legend td span.highlight-icon {
  margin-left: -0.35em;
  margin-right: 0.3em;
}

.chart-legend table td:first-child {
  text-align: left;
}

.chart-legend table td:last-child {
  text-align: right;
  padding-left: 0.5em;
}

.chart-legend table tr.space-under > td {
  padding-bottom: 1em;
}
