:root {
  --taskbar-margin-left: 25px;
  --taskbar-margin-right: 25px;
  --taskbar-margin-bottom: 25px;
  --taskbar-control-width: 10rem;
  --taskbar-control-width-wide: 11rem;
  --taskbar-padding-top: 1rem;
  --taskbar-tabs-horiz-margin: 5px;
}

.task_bar {
  width: calc(var(--taskbar-width) + 20px);
  min-width: calc(var(--taskbar-width) + 20px);
  /*height: calc(100vh - var(--app-header-height));*/
  height: 100%;
  max-height: calc(100vh - var(--app-header-height-internal));
  background-color: var(--taskbar-background-col);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  flex-grow: 1;
  padding-top: var(--taskbar-padding-top);
  justify-content: start;
  padding-bottom: var(--taskbar-margin-bottom);
  overflow-y: auto;
  overflow-x: auto;
}

.task_bar::-webkit-scrollbar {
  background-color: var(--background-col);
  width: 10px;
}

.task_bar::-webkit-scrollbar-thumb {
  background-color: var(--workspace-background-col);
  border-radius: 10000px;
}

.task_bar_hidden {
  visibility: collapse !important;
}

.task_bar hr {
  height: 1px;
  background-color: var(--primary-text);
  border: none;
  width: 100%;
}

.task_bar_group {
  /*border-top: 1px solid var(--light-grey-divider);*/
  margin-left: var(--taskbar-margin-left);
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}

.task_bar_graph_controls {
  composes: task_bar_group;
  min-height: var(--chart_height);
}

.task_bar_group:first-child {
  border: none;
}

.task_bar_spacer {
  height: 100%;
}

.task_bar_header {
  text-align: left;
  padding: 10px 10px 6px 0;
  margin: 0;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 1.2em;
}

.task_bar_section_body {
  padding: 0 var(--taskbar-margin-right) 20px 0;
}

/* fill space between last populated task_bar_section and  task_bar_section_extras */
.task_bar_section_filler {
  flex-grow: 1;
}

.task_bar_section_extras {
  position: relative;
  composes: task_bar_section_body;
}

.task_bar_component {
  text-align: right;
  width: 100%;
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}

.task_bar_component label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
  width: 100%;
  line-height: 26px;
  margin-bottom: 10px;
  align-items: baseline;
}

.task_bar_vpad_component {
  padding-top: 10px;
  padding-bottom: 10px;
}

.task_bar_select {
  width: var(--taskbar-control-width);
  padding: 0 4px;
  margin-left: auto;
  margin-right: 0;
  font-size: 1rem;
}

.task_bar_select_wide {
  composes: task_bar_select;
  width: var(--taskbar-control-width-wide);
}

.task_bar_select_fullwidth {
  composes: task_bar_select;
  width: 100%;
  text-align: right;
}

.task_bar_date_picker {
  composes: task_bar_select;
  margin-left: auto !important;
  width: var(--taskbar-control-width-wide);
  padding: 0;
}

.task_bar_date_picker_full_width {
  composes: task_bar_date_picker;
  align-self: center;
}

.task_bar_date_picker_arrow_grid .task_bar_date_picker {
  width: auto;
  margin: auto;
}

.task_bar_date_picker input {
  padding: 0.5em 0.5em !important;
  padding-left: 14px !important;
}
.task_bar_date_picker div div {
  margin-left: 0;
}

.task_bar_date_picker div div button {
  padding-left: 0;
}

.task_bar_textfield {
  width: var(--taskbar-control-width);
  margin-left: auto !important;
  margin-right: 0;
}

.task_bar_combifield {
  width: var(--taskbar-control-width);
  margin-left: auto !important;
  margin-right: 0;
  display: flex;
  flex-direction: row;
}

.task_bar_combifield .task_bar_input {
  width: auto;
  padding-left: 14px;
  flex-grow: 2;
}

.lanes_buttons {
  min-width: 2em !important;
  flex-grow: 1;
}

.segment_info {
  /* border: 1px solid orange; */
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.segment_info_key {
  text-align: left;
  width: 50%;
  display: inline-block;
}
.segment_info_value {
  text-align: left;
  width: 50%;
  display: inline-block;
}

.typical_month_missing_warning {
  text-align: start;
  color: var(--secondary-text);
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.threshold_slider {
  text-align: left;
  width: 100%;
  padding: 10px 10px 10px 0;
  /* border: 1px solid orange; */
}

.threshold_slider div {
  padding-left: 10px;
}

td.TableCell {
  padding: 0.5em;
  padding-left: 0 !important;
}

.task_bar_component_bottom {
  /*width: var(--taskbar-width);*/
  /*padding-left: var(--taskbar-margin-left);*/
  padding-right: var(--taskbar-margin-left);
}

.export_button {
  width: 100%;
  margin: 0px;
  padding: 0.2em 10px;
  height: auto;
}

.percent_adornment {
  padding-left: 2px !important;
}

.percent_adornment > p {
  color: var(--primary-text);
}

/* Remove up/down arrows on number input*/
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.overflowLabel {
  overflow: visible;
  flex-wrap: wrap;
  justify-content: space-between !important;
}

.metrics_space_above {
  margin-top: 0.5em;
}
