.nav_list {
  display: flex;
  flex-direction: column;
}

button.logout_link {
  padding: 0;
  margin-left: 0.4em;
  text-decoration: underline;
  font-size: 0.8rem;
  height: 1em;
  min-width: unset;
}

a.hidden_features {
  padding: 0;
  margin-left: 0.4em;
  color: #575757;
}
