.header_padded {
    text-align: left;
    padding: 10px 10px 6px 0;
    margin: 0;
    font-weight: normal;
    font-size: 1.2em;
    padding-left: var(--taskbar-margin-left);
    padding-right: var(--taskbar-margin-right);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.no_left_padding {
    padding-left: 0px;
}

.bookmark_toolbar_list {
    margin-left: 0;
    overflow-y: auto;
    /*max-height:20vh;*/
    flex-grow:0;
    width:100%;
    scrollbar-width: thin;
    scrollbar-color: var(--workspace-background-col) var(--background-col);
    padding: 10px 10px 6px 0;
}

.no_bookmarks_message {
    padding-left: var(--taskbar-margin-left) !important;
    text-align: left;
    font-style:italic;
}

.bookmark_toolbar_list::-webkit-scrollbar {
    background-color: var(--background-col);
    width: 10px;
}

.bookmark_toolbar_list::-webkit-scrollbar-thumb {
    background-color: var(--workspace-background-col);
    border-radius: 10000px;
}


.bookmark_item_button {
    padding-left: var(--taskbar-margin-left) !important;
}

.bookmark_edit_button {
    opacity: 0.1;
}

.bookmark_edit_button:hover {
    opacity:1;
}

.bookmark_delete_button {
    opacity: 0.1;
}

.bookmark_delete_button:hover {
    opacity:1;
}

.edit_name_field {
    margin-left: var(--taskbar-margin-left) !important;
}

.confirm_delete_field {
    composes: edit_name_field;
    color: lightpink;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.search_text_field {
    border: none !important;
    width: 100%;
    justify-content: flex-start;
}

.saved_route_item {

}

.saved_route_item_selected {
    padding-left: 0;
    border-left: 4px solid var(--gold-col-transparent);
    background: none !important;
}

.saved_route_item_selected span {
    font-weight: 500;
}