.login_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 700px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--workspace-background-col);
  padding: 2em 4em 2em 4em;
  color: var(--primary-text);
}

.login h1 {
  margin-bottom: 0;
}

.password_forgotten {
  margin-top: 1em;
  margin-bottom: 1em;
}

.submit_button {
  margin-top: 1em;
}

.login_via_sso_button {
  color: brown;
  margin-top: 1em;
}

.login_field_name {
  margin-top: 1.5rem;

  margin-bottom: 0.5rem;
}

.login_error_message {
  color: red;
  font-size: 1.3rem;
}

.login_field_error_message {
  color: red;
  font-size: 0.8rem;
}

.terms_content_container {
  composes: login_container;
  min-width: 100%;
  background-color: var(--chart_background_color);
}

.terms_content {
  composes: login;
  max-width: 60em;
  width: auto;
  min-height: 100%;
}

.terms_content_text {
  max-width: 42em;
  text-align: justify;
}

.terms_input {
  width: 20em;
  margin: 0.5em 1em !important;
  padding: 0;
}

.terms_second_org_container {
  text-align: left;
}

.terms_second_org {
  composes: terms_input;
  text-align: left;
  align-self: center;
  width: 40em;
  align-content: start;
  padding: 0;
}